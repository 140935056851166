<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-text>
        <v-card-title>Dodaj korisnika</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field color="#137547" dense outlined v-model="editedItem.firstName" label="Ime"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field color="#137547" dense outlined v-model="editedItem.lastName" label="Prezime"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field color="#137547" dense outlined v-model="editedItem.userName" label="Korisničko ime"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field color="#137547" dense outlined v-model="editedItem.email" label="Email"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text class="mr-2" @click="close"> Odbaci </v-btn>
        <v-btn color="success" @click="addUser"> Sačuvaj </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showAddUsers: false,
      editedItem: {
        id: "",
        mainImage: "",
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
      },
      mainImage: null,
      url: {
        mainImage: null,
      },
    };
  },
  methods: {
    uploadFile(e) {
      this.mainImage = e;
      this.url.mainImage = URL.createObjectURL(e);
    },
    async addUser() {
      const formData = new FormData();
      formData.append("FirstName", this.editedItem.firstName);
      formData.append("LastName", this.editedItem.lastName);
      formData.append("UserName", this.editedItem.userName);
      formData.append("IsActive", true);
      formData.append("Email", this.editedItem.email);
      formData.append("Type", 3);
      // formData.append("MainImgFile", this.mainImage);
      await axios.post("User", formData, { headers: { "Content-Type": "multipart/form-data" } });
      this.$emit("userAdded"); //emitujem ga u child compon
      this.show = false;
    },
    close() {
      this.show = false;
      this.editedItem.firstName = null;
      this.editedItem.lastName = null;
      this.editedItem.userName = null;
      this.editedItem.email = null;
      this.mainImage = null;
    },
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
